import React from "react"

import Card from "../components/card"
import Layout from "../components/layout"
import Snippet from "../components/snippet"

import partnerJson from "../../content/meta/partners.json"
import style from "./partners.module.css"

const GuidePage = () => (
	<Layout
		meta={{
			title: "Conference Guide",
			description:
				"Accento Digital takes place on Hopin - here's how to use it.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<div>
			<Snippet id="guide-intro" />
			<div className={style.cards}>
				{partnerJson.companies.map(partner => (
					<Card
						size="medium"
						className={style.partner}
						imageSlug={partner.imageSlug}
						imageLink={partner.url}
						invertColors
					/>
				))}
			</div>
		</div>
		<Snippet id="guide-requirements" />
		<Snippet id="guide-general" />
		<Snippet id="guide-sessions" />
		<Snippet id="guide-networking" />
		<Snippet id="guide-hints" />
	</Layout>
)

export default GuidePage
